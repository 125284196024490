<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="上游编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="组别编号搜索" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="收款人手机" v-if="user_info.tel!='18434858666'" >
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名" v-if="user_info.tel!='18434858666'">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨结算" value="1"></el-option>
					<el-option label="按方结算" value="2"></el-option>
					<el-option label="按趟结算" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要回程" value="1"></el-option>
					<el-option label="不需回程" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="匹配方式">
				<el-select class="el_input" v-model="form.match_way" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="收款人抢单" value="1"></el-option>
					<el-option label="指定后收款人接单" value="2"></el-option>
					<el-option label="收款人竞价中标" value="3"></el-option>
					<el-option label="收款人扫码接单" value="4"></el-option>
					<el-option label="网点线下匹配" value="5"></el-option>
					<el-option label="合作方匹配" value="6"></el-option>
					<el-option label="后台匹配" value="7"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="装货地">
				<el-cascader class="el_input" v-model="form.case_cities" @change="ser_para_case_cities_change" placeholder="装货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="到货地">
				<el-cascader class="el_input" v-model="form.aim_cities" @change="ser_para_aim_cities_change" placeholder="到货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="发到时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item" >
			
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="success" @click="tords_start">批量发车</el-button>
				<el-button type="success" @click="tords_arrived">批量到货</el-button> -->
				<!-- <el-button type="success" @click="tords_pay_open" v-if="(not_pay_tels.indexOf(user_info.tel)>-1)?false:true">批量支付</el-button> -->
				<!-- <el-button type="success" v-if="(not_pay_tels.indexOf(user_info.tel)>-1)?false:true" @click="tords_pay_open('批量支付运费')">批量支付</el-button> -->
				<el-button type="success" v-if="(not_pay_tels.indexOf(user_info.tel)>-1)?false:true" @click="tords_pay_open('批量请款')">批量请款</el-button>
				<!-- <el-button type="success" @click="tords_invoice_apply">批量申请开票</el-button> -->
				<!-- <el-button type="success" class="btn" @click="set_truck_owner">设置收款人</el-button> -->
			</el-form-item>
		</el-form>
		
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{list.total_freight_has_payed}}元</span>
			<span>总运费:{{list.total_freight}}元</span>
			<span>总服务费:{{list.total_service}}元</span>
			<span>总吨数:{{list.total_cargo_weight}}吨</span>
			<!-- <span v-if="tick_freight>0">勾选总运费:{{tick_freight}}元</span> -->
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table 
				size="small" 
				height="100%" 
				:data="list.data" 
				:border="true" 
				:stripe="true" 
				v-loading="list.loading" 
				@selection-change="tords_list_choose" 
			>
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="运单编号/组别编号/上游单号" width="195px">
					<template slot-scope="scope">
						<div class="tab_line_item">成交:{{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">编号:{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:'无'}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:'无'}}</div>
						<div v-if="scope.row.item_name">项目名称:{{scope.row.item_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}</div>
						<div class="tab_line_item">{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.case_link_man_text}}/{{scope.row.case_link_tel_text}}</div>
						<div class="tab_line_item">发车时间:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.aim_link_man_text}}/{{scope.row.aim_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item">重量:{{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">体积:{{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费(元)/其他信息" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">
							<span class="freight_total">{{scope.row.payed_status_text}}</span>/
							<el-button class="btn_left" @click="tord_payed_list(scope.row)" size="mini" type="text">支付记录</el-button>
						</div>
						<div class="tab_line_item">{{`${scope.row.cost_info.freight_total}/${scope.row.cost_info.has_payed_total}`}}</div>
						<div class="tab_line_item">{{`${scope.row.settlement_type_text}/${scope.row.is_need_return_text}`}}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付记录(最多4条)" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item" v-for="(payed_item,index) in scope.row.payed_list" :key="index">{{`${payed_item.much}/${payed_item.pay_status}/${payed_item.pay_mark}`}}</div>
					</template>
				</el-table-column>

				<el-table-column label="司机/货车信息" width="180px">
					<template slot-scope="scope">
						<!-- <div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div> -->
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">司机审核状态: <span :class="scope.row.driver_info.status==2?'green':scope.row.driver_info.status==3?'red':''">{{scope.row.driver_info_status_text}}</span> </div>
						<div class="tab_line_item" v-if="scope.row.driver_info.status==3">司机打回原因:{{scope.row.driver_info.faild_reason}}</div>
						<div class="tab_line_item">{{scope.row.truck_info.truck_plate_num}}/{{scope.row.truck_info.truck_type_name_text}}</div>
						<div class="tab_line_item">车辆审核状态: <span :class="scope.row.truck_info.status==2?'green':scope.row.truck_info.status==3?'red':''">{{scope.row.truck_info_status_text}}</span> </div>
						<div class="tab_line_item" v-if="scope.row.truck_info.status==3">车辆打回原因:{{scope.row.truck_info.faild_reason}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人信息" width="180px">
					<template slot-scope="scope">
					   <div v-if="scope.row.truck_owner_bank_list">
							<div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
							<div class="tab_line_item" v-if="scope.row.truck_owner_bank_list.num==1">收款人姓名:{{scope.row.truck_owner_bank_list.list[0].card_user_name}}</div>
							<div class="tab_line_item" v-if="scope.row.truck_owner_bank_list.num==1">{{scope.row.truck_owner_bank_list.list[0].bank_cname}}</div>
							<div class="tab_line_item" >{{scope.row.truck_owner_bank_list.num==1?scope.row.truck_owner_bank_list.list[0].card_num.replace(/^(\d{4})\d+(\d{4})$/,'$1 **** **** $2'):scope.row.truck_owner_bank_list.num==0?'未绑卡':'绑定多张卡'}}</div>
							<el-button class="tab_line_item" v-if="scope.row.truck_owner_bank_list.num>1" @click="truck_owner_bank_show=true" size="mini" type="text">更多</el-button>
							<div v-if="truck_owner_bank_show">
								<div    
								v-for="(item, index) in scope.row.truck_owner_bank_list.list.shift()"
								:key="index">

								</div>
								<div>{{item.bank_cname}}</div>
								<div>{{item.card_num}}</div>
							</div>
					   </div>
	                   <div v-if="scope.row.driver_bank_list">
						   	<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
							<div class="tab_line_item" v-if="scope.row.driver_bank_list.num==1">收款人姓名:{{scope.row.driver_bank_list.list[0].card_user_name}}</div>
							<div class="tab_line_item" v-if="scope.row.driver_bank_list.num==1">{{scope.row.driver_bank_list.list[0].bank_cname}}</div>
							<div class="tab_line_item" >{{scope.row.driver_bank_list.num==1?scope.row.driver_bank_list.list[0].card_num.replace(/^(\d{4})\d+(\d{4})$/,'$1 **** **** $2'):scope.row.driver_bank_list.num==0?'未绑卡':'绑定多张卡'}}</div>
							<el-button class="tab_line_item" v-if="scope.row.driver_bank_list.num>1" @click="driver_bank_show=!driver_bank_show" size="mini" type="text">{{driver_bank_show?'关闭':'更多'}}</el-button>
							<div v-if="driver_bank_show">
								<div    
								v-for="(item, index) in scope.row.driver_bank_list.list"
								:key="index">
									<div>{{item.bank_cname}}</div>
									<div>{{item.card_num}}</div>
								</div>

							</div>
					   </div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="155px">
					<template slot-scope="scope">
						<div class="tab_line_item">状态:{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							锁定:{{scope.row.lock_status_text}}/ 
							<el-button @click="tord_lock_reason_show(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1" style="color:red">查看原因</el-button>
						</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
						<div class="tab_line_item">轨迹审核: <span :class="scope.row.trajectory_status==4?'red':''">{{scope.row.trajectory_status_text}}</span> </div>
						<div class="tab_line_item">运审核状态: <span :class="scope.row.check_status==2?'green':scope.row.check_status==3?'red':''">{{scope.row.check_status_text}}</span> </div>
						<div class="tab_line_item" v-if="scope.row.check_status==3">运单打回原因:{{scope.row.check_reason}}</div>
						<div class="tab_line_item">支付校验: <span :class="scope.row.pay_check_status==1?'green':scope.row.pay_check_status==2?'red':''">{{scope.row.pay_check_status_text}}</span> </div>
						<div class="tab_line_item" v-if="scope.row.pay_check_status==2">支付异常原因:{{scope.row.pay_check_msg}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">

						<el-button class="btn_left" style="margin-right: 10px;" @click="voucher_show(scope.row.truck_tord_num)" size="mini" type="text">凭证</el-button>
						<el-button
							v-if="(not_pay_tels.indexOf(user_info.tel)>-1)?false:true"
							class="btn_left" @click="tords_pay_open2(scope.row,'请款',2)" size="mini" type="text">
							请款
						</el-button>
						<el-button class="btn_left " style="margin-right: 10px;" @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button>
						<el-button class="btn_left" @click="tord_edit_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button class="btn_left" @click="tord_edit_record(scope.row)" size="mini" type="text">修改记录</el-button>
						<el-button class="btn_left" style="margin-left: 10px;" v-if="scope.row.check_status==3" @click="user_appeal_add(scope.row)" size="mini" type="text">申诉</el-button>
						<el-button class="btn_left" v-if="scope.row.check_status!=1" @click="admin_appeal_list(scope.row)" size="mini" type="text">申诉记录</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 200, 500, 1000]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 支付记录弹出层 -->
		<!-- <payList 
			:pay_list="pay_list.data" 
			:calc_mode="pay_list.calc_mode" 
			:truck_tord_num="pay_list.truck_tord_num" 
			:is_show="pay_list.is_show" 
			@pay_list_close="tord_pay_close"
		></payList> -->

		<!-- 支付创建弹出层 -->
		<payCreat 
			:tord_info="pay_creat.data" 
			:is_show="pay_creat.is_show" 
			:title="pay_creat.title"
			:type='pay_creat.type'
			@pay_creat_close="tord_pay_close"
		></payCreat>

		<!-- 批量支付弹出层 -->
		<payBatch 
		    :title="pay_batch.title"
			:is_show="pay_batch.is_show" 
			:tord_list="pay_batch.tord_list" 
			@close="pay_batch_close"
			:type ="pay_batch.type"
			:pay_service_status="pay_batch.pay_service_status"
		></payBatch>

		<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>

		<!-- 货车轨迹 -->
		<trucktTrajectory 
			v-if="trajectory.is_show"
			:case_county="trajectory.case_county"
			:aim_county="trajectory.aim_county"
			:start_time="trajectory.start_time"
			:end_time="trajectory.end_time"
			:truck_plate_num="trajectory.truck_plate_num"
			:driver_id="trajectory.driver_id"
			:truck_tord_num="trajectory.truck_tord_num"
			@close="truck_trajectory_close"
		    :case_position="trajectory.case_position"
			:aim_position="trajectory.aim_position"
			:ctr="trajectory.ctr"
		></trucktTrajectory>

		<!-- 运单锁定原因 -->
		<el-dialog title="运单锁定原因" top="1vh" width="600px" :visible.sync="lock.is_show">
			<div>{{lock.lock_reason}}</div>
		</el-dialog>

		<!-- 运单修改界面 -->
		<tordEdit 
			@edited="tord_edit_close"
			:tord_info="tord_edit.tord_info" 
			:is_show="tord_edit.is_show"
		></tordEdit>

		<!-- 绑定银行卡 -->
		<bindingBankCard
			:is_show="bank_card.is_show"
			:data="bank_card.data"
		></bindingBankCard>

		<!-- 修改记录 -->
		<editRecord
			:is_show="edit_record.is_show"
			:data="edit_record.data"
		></editRecord>

		<!-- 申诉记录 -->
		<TordAppeals
			:is_show="tord_appeals_list.is_show"
			:truck_tord_num="tord_appeals_list.truck_tord_num"
		></TordAppeals>

		<!-- 承运商设置 -->
		<setTruckOwnerInfo
			:is_show="truck_owner_info.is_show"
			:data ="truck_owner_info.data"
			:check_info="truck_owner_info.check_info"
			@edited="get_page_data"
		></setTruckOwnerInfo>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import payCreat from '../tord/pay/pay_creat.vue'
	import payBatch from './pay_batch.vue'
	import {regionData,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import voucher from '../voucher/voucher_before_end.vue'
	import tordEdit from './edit.vue'
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	import bindingBankCard from '../../../../real_time/tord/binding_bank_card.vue'
	import editRecord from './edit_record.vue'
	import TordAppeals from '../tord/tord_appeals.vue'
	import setTruckOwnerInfo from '../tord/set_truck_owner_info.vue'
	export default {
		components:{
			payCreat,
			payBatch,
			voucher,
			trucktTrajectory,
			tordEdit,
			bindingBankCard,
			editRecord,
			TordAppeals,
			setTruckOwnerInfo
		},
		data() {
			return {
				driver_bank_show:false,
				//这些子账户登录时隐藏支付记录
				not_pay_tels:['18672692607','13377857911','18696415263','15171431533','15971456412','18162681383','18819339971',],
				
				//搜索条件参数
				ser_para:{
					cargo_type:[],//货物类型
					cities:regionData,//省市区数据
				},

				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					status:'',//状态
					payed_status:'',//支付状态
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					item_name:'',//项目名称
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time:"",//发车时间
                    end_time:"",//到货时间
				},
                date_value:"",
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'', //总运费
					total_service:'',//总服务费
					total_freight_has_payed:'',//总计
					total_cargo_weight:'',//总吨数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//待支付列表
				pay_list:{
					data:[],
					calc_mode:'',
					truck_tord_num:'',
					is_show:0,
				},

				//发起支付
				pay_creat:{
					data:{},
					is_show:0,
					title:'',
					type:0
				},

				//批量支付
				pay_batch:{
					tord_list:[],
					is_show:0,
					title:'',
					type:0,//支付类型 1支付 2请款
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
					case_position:{},
					aim_position:{}
				},

				//选中的运单
				tords_choosed:[],
				
				//运单锁定原因
				lock:{
					is_show:false,
					lock_reason:'',
				},

				//运费修改相关
				tord_edit:{
					is_show:0,
					tord_info:{}
				},
				//银行卡绑定
				bank_card:{
					is_show:0,
					data:{},
				},
				edit_record:{
					is_show:0,
					data:[]
				},
				tord_appeals_list:{
					truck_tord_num:'',
					is_show:0
				},
				//设置承运商
				truck_owner_info:{
					is_show:0,
					data:null,
					check_info:'',
				},
			}
		},
		computed:{
			...mapState(['user_info','main_user_info'])
		},
		created() {
			
			//获取可用搜索参数
			this.get_ser_para()

			//读取货源
			this.get_page_data()
		},
		methods: {
		    //承运商设置
			set_truck_owner(){
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				let tick_freight = 0
				this.tords_choosed.forEach(item=>{
					tick_freight += Number(item.cost_info.freight_total) * 100

				})
				tick_freight = tick_freight/100
				this.truck_owner_info.data = this.tords_choosed
				this.truck_owner_info.check_info = '当前勾选总条数'+this.tords_choosed.length + '' +'总运费'+tick_freight+'元(不含服务费)'
				this.truck_owner_info.is_show ++
			},
			//运单申诉记录
			admin_appeal_list(item){
				this.tord_appeals_list.truck_tord_num = item.truck_tord_num
				this.tord_appeals_list.is_show ++
			},
			//运单申诉
			user_appeal_add(item){
				 this.$prompt('请输入申诉理由', '提示', {
					}).then(({ value }) => {
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'user_appeal_add',
								truck_tord_num:item.truck_tord_num,
								matter:value
							},callback:(data)=>{
								this.$message({
									type: 'success',
									message: '申诉提交成功'
								});  
							}
						})
					}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消申诉'
					});       
				});
			},
		    //运单修改记录
			tord_edit_record(item){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'ord_edit_record',
						truck_tord_num:item.truck_tord_num,
					},callback:(data)=>{
						this.edit_record.data = data.list
						this.edit_record.is_show ++
					}
				})
			
			},
			//银行卡绑定
			binding_bankcard(data){
				
				this.bank_card.is_show ++
				this.bank_card.data = data
			},
			//运费相关操作
			tord_edit_open(item){//打开修改界面
				this.tord_edit.is_show++;
				this.tord_edit.tord_info={...item}
			},
			tord_edit_close(){//关闭修改界面
				this.tord_edit.is_show=0
				this.tord_edit.tord_info={}

				//刷新
				this.get_page_data()
			},
			//运单删除
			tord_delete(item){
				this.$my.other.confirm({
					content:"是否确认提交删除?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_hide',
								truck_tord_nums:[item.truck_tord_num],
								type:2
							},callback:(res)=>{
								this.$my.other.msg({
									str:'提交成功',
									type:'success'
								});
							}
						})
					}
				});
			},
			//选择待支付
			tords_list_choose(event){

				//置入
				this.tords_choosed=event
			},
			
			//轨迹
			truck_trajectory_open(item){
				this.trajectory.case_position.lat = item.case_lat
				this.trajectory.case_position.lng = item.case_lng
				this.trajectory.aim_position.lat = item.aim_lat
				this.trajectory.aim_position.lng = item.aim_lng
				this.trajectory.truck_tord_num = item.truck_tord_num
				this.trajectory.case_county=item.case_county;
				this.trajectory.aim_county=item.aim_county;
				this.trajectory.start_time=parseInt(item.start_time);
				this.trajectory.end_time=item.status==2?parseInt(new Date().getTime()/1000):parseInt(item.arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.driver_id = item.driver_info.id
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},
			
			//查看运单锁定原因
			tord_lock_reason_show(item){
				this.lock.is_show=true;
				this.lock.lock_reason=item.lock_reason;
			},
			
			//凭证相关操作
			voucher_show(truck_tord_num){//显示凭证
				this.voucher.is_show++;
				this.voucher.truck_tord_num=truck_tord_num
			},

			//支付记录
			tord_payed_list(item){

				//跳转
				this.$router.push({
					path:'/pages/sub_user/main_user_sys/tord/tord_pay_list',
					query:{
						truck_tord_num:item.truck_tord_num
					}
				});
			},

			//批量支付
			tords_pay_open(title,type){
				
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要到货的运单
				let tords_need_pay=[];
				for(var item of this.tords_choosed){
					if(item.cost_info.freight_total==0){
						this.$my.other.msg({
							type:'warning',
							str:`运单'${item.truck_tord_num}'没有核定运费,请查看`
						})
						return;
					}

					if(item.payed_status==3){
						this.$my.other.msg({
							type:'warning',
							str:`运单'${item.truck_tord_num}'已支付完毕,请查看`
						})
						return;
					}

					tords_need_pay.push(item);
				}

				//已选择运单中没有需要到货的
				if(tords_need_pay.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要支付的'
					})
					return;
				}

				//置入数据
				this.pay_batch.tord_list=tords_need_pay
				this.pay_batch.title = title
				this.pay_batch.type = type
				this.pay_batch.pay_service_status = this.list.pay_service_status
				//打开支付界面
				this.pay_batch.is_show++;
			},
			pay_batch_close(){

				//关闭支付界面
				this.pay_batch.is_show=0;

				//置入数据
				this.pay_batch.truck_tord_nums=[]

				//刷新页面
				this.get_page_data()
			},

			//单个支付 调用批量支付样式
			tords_pay_open2(data,title,type){
				//没有选择运单
				// if(this.tords_choosed.length==0){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'请选择运单'
				// 	})
				// 	return;
				// }

				//汇集需要到货的运单
				let tords_need_pay=[];
				if(data.cost_info.freight_total==0){
					this.$my.other.msg({
						type:'warning',
						str:`运单'${data.truck_tord_num}'没有核定运费,请查看`
					})
					return;
				}

				if(data.payed_status==3){
					this.$my.other.msg({
						type:'warning',
						str:`运单'${data.truck_tord_num}'已支付完毕,请查看`
					})
					return;
				}

				tords_need_pay.push(data);

				//已选择运单中没有需要到货的
				if(tords_need_pay.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要支付的'
					})
					return;
				}

				//置入数据
				this.pay_batch.tord_list=tords_need_pay
				// this.pay_batch.pay_service_status = this.list.pay_service_status
				this.pay_batch.type = type
				this.pay_batch.title = title
				//打开支付界面
				this.pay_batch.is_show++;
			},
			tord_pay_close(){
				
				//清空支付记录信息
				this.pay_list.data=[],

				//关闭支付记录界面
				this.pay_list.is_show=0;

				//清空支付创建数据
				this.pay_creat.data={},

				//关闭支付创建界面
				this.pay_creat.is_show=0;

				//刷新页面
				this.get_page_data()
			},
			
			//批量确认到货
			tords_arrived(){

				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要到货的运单
				let tords_need_arrived=[];
				for(var item of this.tords_choosed){
					if(item.status==2){
						tords_need_arrived.push(item.truck_tord_num);
					}
				}

				//已选择运单中没有需要到货的
				if(tords_need_arrived.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要到货的'
					})
					return;
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量到货',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_arrived_by_sub_user',
								truck_tord_nums:tords_need_arrived,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//确认到货
			tord_arrived(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认到货',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_arrived_by_sub_user',
								truck_tord_nums:[item.truck_tord_num],
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//批量确认发车
			tords_start(){

				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要发车的运单
				let tords_need_start=[];
				for(var item of this.tords_choosed){
					if(item.status==1){
						tords_need_start.push(item.truck_tord_num);
					}
				}

				//已选择运单中没有需要发车的
				if(tords_need_start.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要发车的'
					})
					return;
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量发车',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_start_by_sub_user',
								truck_tord_nums:tords_need_start,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//确认发车
			tord_start(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认发车',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_start_by_sub_user',
								truck_tord_nums:[item.truck_tord_num],
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form.case_prov=CodeToText[event[0]];
				this.form.case_city=CodeToText[event[1]];
				this.form.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form.aim_prov=CodeToText[event[0]];
				this.form.aim_city=CodeToText[event[1]];
				this.form.aim_county=CodeToText[event[2]];
			},

			//清空查询条件
			ser_para_clear(){
				
				this.form={
					truck_tord_num:'',//货源编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					status:'',//状态
					payed_status:'',//支付状态
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					item_name:'',//项目名称
					start_time:"",//发车时间
                    end_time:"",//到货时间

					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				}
				this.date_value=''
				this.page.p = 1
				if(this.user_info.tel=='18434858666'){
					this.form.truck_owner_tel = '18434858666'
				}else{
					this.form.truck_owner_tel = ''
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){
				//时间处理
				if (this.date_value) {
					this.form.start_time = parseInt(this.date_value[0] / 1000);
					this.form.end_time= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.start_time = "";
					this.form.end_time = "";
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){
				
				//收款人为张宽手机号 隐藏收款人电话搜索 强制收款人电话为张宽手机号
				if(this.user_info.tel=='18434858666'){
					this.form.truck_owner_tel = '18434858666'
				}

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_by_sub_user',
						...this.form,
						...this.page,
						quotation_status:4,
						 payed_status:12
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//总运费
						this.list.total_freight = data.total.total_freight
						//总计
                        this.list.total_freight_has_payed = (Number(data.total.total_freight)+Number(data.total.total_service)).toFixed(2)
						console.log(this.list.total_freight_has_payed)

						//总吨数
						this.list.total_cargo_weight = data.total.total_cargo_weight
						//总服务费
						this.list.total_service = data.total.total_service

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";
							
							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							/* 装货地其他数据(json) */
							item.case_other_obj=JSON.parse(item.case_other);

							//装货时间
							if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

							}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

							}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

							}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

								item.case_other_obj.case_date="未设置";
							}

							//发车时间
							if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

							}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

							}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

							}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
								
								item.case_other_obj.start_time="未设置";
							}

							//发车时间是否强制校验
							switch(item.case_other_obj.start_time_is_check){
								case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
								case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
							}
							if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								item.case_other_obj.start_time_is_check_text=""
							}

							/* 卸货地其他数据(json) */
							item.aim_other_obj=JSON.parse(item.aim_other);

							//卸货时间
							if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

							}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

							}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

							}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
								
								item.aim_other_obj.aim_date="未设置";
							}

							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}
							//司机审核状态
							switch(item.driver_info.status){
								case '1':item.driver_info_status_text="审核中";break;
								case '2':item.driver_info_status_text="审核通过";break;
								case '3':item.driver_info_status_text="审核未通过";break;
							}
							//货车审核状态
							switch(item.truck_info.status){
								case '1':item.truck_info_status_text="审核中";break;
								case '2':item.truck_info_status_text="审核通过";break;
								case '3':item.truck_info_status_text="审核未通过";break;
							}
							//锁定状态
							switch(item.lock_status){
								case '1':item.lock_status_text="已锁定";break;
								case '2':item.lock_status_text="未锁定";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需回程";break;
								case '2':item.is_need_return_text="不需回程";break;
							}

							//匹配方式
							switch(item.match_way){
								case '1':item.match_way_text="收款人抢单";break;
								case '2':item.match_way_text="指定后收款人接单";break;
								case '3':item.match_way_text="收款人竞价中标";break;
								case '4':item.match_way_text="收款人扫码接单";break;
								case '5':item.match_way_text="网点线下匹配";break;
								case '6':item.match_way_text="合作方匹配";break;
								case '7':item.match_way_text="后台匹配";break;
							}

							//运费总额
							if(item.cost_info.freight_total){
								item.cost_info.freight_total_text=item.cost_info.freight_total+"元";
							}else item.cost_info.freight_total_text="未设置";

							//已付总额
							item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+"元"

							//运费支付状态
							switch(item.payed_status){
								case '1':item.payed_status_text="未支付";break;
								case '2':item.payed_status_text="部分支付";break;
								case '3':item.payed_status_text="支付完毕";break;
							}
							item.trajectory_status_text = ''
							//运费支付状态
							switch(item.trajectory_status){
								case '1':item.trajectory_status_text="待校验 ";break;
								case '2':item.trajectory_status_text="正常";break;
								case '3':item.trajectory_status_text="异常";break;
								case '4':item.trajectory_status_text="无轨迹";break;
							}
							//运单审核状态
							switch(item.check_status){
								case '1':item.check_status_text="审核中";break;
								case '2':item.check_status_text="审核通过";break;
								case '3':item.check_status_text="审核打回";break;
							}
							//运费支付状态
							switch(item.pay_check_status){
								case 1:item.pay_check_status_text="正常可支付 ";break;
								case 2:item.pay_check_status_text="异常无法支付";break;
							}
							//货车类型
							item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+'/'+item.truck_info.truck_type_name2

							//清空单个运单的支付记录
							item.payed_list=[];
						}

						//渲染
						this.list.data=data.list

						//获取并置入支付记录
						this.get_and_show_payed_list();
					}
				})
			},

			//获取并置入支付记录
			get_and_show_payed_list(){
				let list_in_page_obj={};
				let truck_tord_nums=[];
				for(var tord_item of this.list.data){
					list_in_page_obj[tord_item.truck_tord_num]=tord_item
					truck_tord_nums.push(tord_item.truck_tord_num);
				}

				//读取页面内运单的支付记录
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_sub_user',
						truck_tord_nums:truck_tord_nums,
					},
					callback:(data)=>{

						//预处理
						for(let item of data.list){
							switch(item.pay_status){
								case '1':var pay_status_text="未支付";break;
								case '2':var pay_status_text="待审核";break;
								case '3':var pay_status_text="支付完成";break;
								case '4':var pay_status_text="支付失败";break;
							}

							if(list_in_page_obj[item.truck_tord_num].payed_list.length<4){
								list_in_page_obj[item.truck_tord_num].payed_list.push({
									much:item.this_payed,
									pay_mark:item.pay_mark?item.pay_mark:"无备注",
									pay_status:pay_status_text
								})
							}
						}
					}
				});
			},

			//获取可用搜索参数
			get_ser_para(){

				//尝试从缓存中取出
				let ser_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(ser_para){
					this.ser_para.cargo_type=[...ser_para.cargo_type]
					this.ser_para.truck_type=[...ser_para.truck_type]
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{

						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.ser_para.cargo_type=[...data.cargo_type]
						this.ser_para.truck_type=[...truck_type]
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.min{
		margin-right: 10px;
	}
	.freight_total{
		color:red;
		font-weight:600;
	}
	.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.green{
		color: rgb(12, 223, 12);
	}
	.red{
		color: red;
	}
</style>